export enum LocationField {
	Id = "id",
	Guid = "guid",
	Title = "title",
	Subtitle = "subtitle",
	Brand = "brand",
	Status = "status",
	City = "city",
	State = "state",
	Country = "country",
	PostalCode = "postalCode",
	Street = "street",
	OpenToPublic = "openToPublic",
	ProductionFacility = "productionFacility",
	YearOpened = "yearOpened",
	YearClosed = "yearClosed",
	Slug = "slug",
	Updated = "updated",
	UpdatedBy = "updatedBy",
	Created = "created",
	CreatedBy = "createdBy",
}
