export enum OrganizationFields {
	ID = "id",
	ExternalID = "externalId",
	HubspotId = "hubspotId",
	Title = "title",
	Status = "status",
	Brands = "brands",
	PPSubscriptions = "ppSubscriptions",
	BMSubscriptions = "bmSubscriptions",
	Owner = "owner",
	Users = "users",
	City = "city",
	State = "state",
	Slug = "slug",
	UpdatedBy = "updatedBy",
	Updated = "updatedDate",
	CreatedBy = "createdBy",
	Created = "createdDate",
}
