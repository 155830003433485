import { tailwindConstants } from "./tailwind.constants";

export const tailwindConfig = {
	purge: false,
	content: [`src/**/*.{js,ts,jsx,tsx}`],
	safelist: [
		"sr-only",
		{
			pattern: /bg-*/,
		},
	],
	darkMode: "media",
	theme: {
		colors: {
			...tailwindConstants.theme.colors,
		},
		fontSize: {
			...tailwindConstants.theme.fontSize,
		},
		fontFamily: {
			...tailwindConstants.theme.fontFamily,
		},
		fontWeights: {
			...tailwindConstants.theme.fontWeights,
		},
		borderRadius: {
			...tailwindConstants.theme.borderRadius,
		},
		extend: {
			colors: {
				...tailwindConstants.theme.extend.colors,
			},
			backgroundImage: {
				...tailwindConstants.theme.backgroundImage,
				none: "none",
			},
			animation: {
				...tailwindConstants.theme.extend.animation,
			},
			keyframes: {
				...tailwindConstants.theme.extend.keyframes,
			},
			borderWidth: {
				...tailwindConstants.theme.extend.borderWidth,
			},
			boxShadow: {
				...tailwindConstants.theme.extend.boxShadow,
			},
			translate: {
				...tailwindConstants.theme.extend.translate,
			},
			minWidth: {
				...tailwindConstants.theme.extend.minWidth,
			},
			minHeight: {
				...tailwindConstants.theme.extend.minHeight,
			},
			maxHeight: {
				...tailwindConstants.theme.extend.maxHeight,
			},
		},
		icons: Object.freeze({
			...tailwindConstants.theme.icons,
		}),
		atomicSizes: Object.freeze({
			...tailwindConstants.theme.atomicSizes,
		}),
		atomicThemes: Object.freeze({
			...tailwindConstants.theme.atomicThemes,
		}),
		componentThemes: Object.freeze({
			...tailwindConstants.theme.componentThemes,
		}),
		componentSizes: Object.freeze({
			...tailwindConstants.theme.componentSizes,
		}),
		// positions: Object.freeze({
		//     ...tailwindConstants.theme.positions,
		// }),
	},
	variants: {
		extend: {},
	},
	glasswareIcons: Object.freeze({
		...tailwindConstants.glasswareIcons,
	}),
	important: true,

	plugins: [
		require("@tailwindcss/forms")({
			strategy: "class",
		}),
		require("tailwind-scrollbar-hide"),
		require("tailwindcss-debug-screens"),
		function ({ addBase, theme }) {
			function extractColorVars(colorObj, colorGroup = "") {
				return Object.keys(colorObj).reduce((vars, colorKey) => {
					const value = colorObj[colorKey];

					const newVars =
						typeof value === "string"
							? { [`--color${colorGroup}-${colorKey}`]: value }
							: extractColorVars(value, `-${colorKey}`);

					return { ...vars, ...newVars };
				}, {});
			}

			addBase({
				":root": extractColorVars(theme("colors")),
			});
		},
	],
};
