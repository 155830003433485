export const tailwindConstants = {
	purge: false,
	darkMode: "media",
	theme: {
		colors: {
			slate: {
				"50": "#f8fafc",
				"100": "#f1f5f9",
				"200": "#e2e8f0",
				"300": "#cbd5e1",
				"400": "#94a3b8",
				"500": "#64748b",
				"600": "#475569",
				"700": "#334155",
				"800": "#1e293b",
				"900": "#0f172a",
			},
			zinc: {
				"50": "#fafafa",
				"100": "#f4f4f5",
				"200": "#e4e4e7",
				"300": "#d4d4d8",
				"400": "#a1a1aa",
				"500": "#71717a",
				"600": "#52525b",
				"700": "#3f3f46",
				"800": "#27272a",
				"900": "#18181b",
			},
			green: {
				"50": "#F2FCDF",
				"100": "#E6F8BF",
				"200": "#CBF17A",
				"300": "#B2EA39",
				"400": "#8BC115",
				"500": "#5D810E",
				"600": "#49650B",
				"700": "#384E08",
				"800": "#243305",
				"900": "#141C03",
			},
			emerald: {
				"50": "#ecfdf5",
				"100": "#d1fae5",
				"200": "#a7f3d0",
				"300": "#6ee7b7",
				"400": "#34d399",
				"500": "#10b981",
				"600": "#059669",
				"700": "#047857",
				"800": "#065f46",
				"900": "#064e3b",
			},
			yellow: {
				"50": "#FEF4A9",
				"100": "#FEF295",
				"200": "#FEED6D",
				"300": "#FDE744",
				"400": "#FDE21C",
				"500": "#EED202",
				"600": "#C6AE02",
				"700": "#9D8B01",
				"800": "#756701",
				"900": "#4C4301",
			},
			orange: {
				"50": "#FEF1DF",
				"100": "#FEE6C9",
				"200": "#FCCD9C",
				"300": "#FBB26F",
				"400": "#FA9443",
				"500": "#F97316",
				"600": "#D65406",
				"700": "#A53B04",
				"800": "#732603",
				"900": "#411302",
			},
			blue: {
				"50": "#E1F1FF",
				"100": "#B8DDFF",
				"200": "#67B6FF",
				"300": "#0074E0",
				"400": "#005FB8",
				"500": "#003B71",
				"600": "#003362",
				"700": "#002B52",
				"800": "#002343",
				"900": "#001B34",
			},
			teal: {
				"50": "#f0fdfa",
				"100": "#ccfbf1",
				"200": "#99f6e4",
				"300": "#5eead4",
				"400": "#2dd4bf",
				"500": "#14b8a6",
				"600": "#0d9488",
				"700": "#0f766e",
				"800": "#115e59",
				"900": "#134e4a",
			},
			cyan: {
				"50": "#D6F6FB",
				"100": "#C1F1F9",
				"200": "#97E8F5",
				"300": "#6DDFF0",
				"400": "#44D6EC",
				"500": "#1ACDE8",
				"600": "#13A6BC",
				"700": "#0E7D8E",
				"800": "#0A545F",
				"900": "#052B31",
			},
			sky: {
				"50": "#f0f9ff",
				"100": "#e0f2fe",
				"200": "#bae6fd",
				"300": "#7dd3fc",
				"400": "#38bdf8",
				"500": "#0ea5e9",
				"600": "#0284c7",
				"700": "#0369a1",
				"800": "#075985",
				"900": "#0c4a6e",
			},
			indigo: {
				"50": "#eef2ff",
				"100": "#e0e7ff",
				"200": "#c7d2fe",
				"300": "#a5b4fc",
				"400": "#818cf8",
				"500": "#6366f1",
				"600": "#4f46e5",
				"700": "#4338ca",
				"800": "#3730a3",
				"900": "#312e81",
			},
			violet: {
				"50": "#f5f3ff",
				"100": "#ede9fe",
				"200": "#ddd6fe",
				"300": "#c4b5fd",
				"400": "#a78bfa",
				"500": "#8b5cf6",
				"600": "#7c3aed",
				"700": "#6d28d9",
				"800": "#5b21b6",
				"900": "#4c1d95",
			},
			fuchsia: {
				"50": "#fdf4ff",
				"100": "#fae8ff",
				"200": "#f5d0fe",
				"300": "#f0abfc",
				"400": "#e879f9",
				"500": "#d946ef",
				"600": "#c026d3",
				"700": "#a21caf",
				"800": "#86198f",
				"900": "#701a75",
			},
			pink: {
				"50": "#fdf2f8",
				"100": "#fce7f3",
				"200": "#fbcfe8",
				"300": "#f9a8d4",
				"400": "#f472b6",
				"500": "#ec4899",
				"600": "#db2777",
				"700": "#be185d",
				"800": "#9d174d",
				"900": "#831843",
			},
			rose: {
				"50": "#fff1f2",
				"100": "#ffe4e6",
				"200": "#fecdd3",
				"300": "#fda4af",
				"400": "#fb7185",
				"500": "#f43f5e",
				"600": "#e11d48",
				"700": "#be123c",
				"800": "#9f1239",
				"900": "#881337",
			},
			red: {
				"50": "#FBCBCB",
				"100": "#F37272",
				"200": "#F04C4C",
				"300": "#ED2727",
				"400": "#D91212",
				"500": "#B30F0F",
				"600": "#9B0D0D",
				"700": "#840B0B",
				"800": "#6C0909",
				"900": "#550707",
			},
			brown: {
				"50": "#EDE5BF",
				"100": "#E9DFB3",
				"200": "#E2D29B",
				"300": "#DCC384",
				"400": "#D5B36C",
				"500": "#CEA154",
				"600": "#9A6C2C",
				"700": "#775222",
				"800": "#382514",
				"900": "#0B0503",
			},

			magenta: {
				"50": "#FFE5FF",
				"100": "#FFCCFF",
				"200": "#FF99FF",
				"300": "#FF66FF",
				"400": "#FF33FF",
				"500": "#FF00FF",
				"600": "#D100D1",
				"700": "#A300A3",
				"800": "#750075",
				"900": "#470047",
			},
			purple: {
				"50": "#F6DDFF",
				"100": "#EFC1FF",
				"200": "#E189FF",
				"300": "#D251FF",
				"400": "#C319FF",
				"500": "#A600E0",
				"600": "#8800B7",
				"700": "#6A008E",
				"800": "#4B0066",
				"900": "#2D003D",
			},
			gray: {
				"50": "#F6F6F6",
				"100": "#E0E0E0",
				"200": "#B5B5B5",
				"300": "#8A8A8A",
				"400": "#5E5E5E",
				"500": "#333333",
				"600": "#2B2B2B",
				"700": "#242424",
				"800": "#1C1C1C",
				"900": "#141414",
			},
			srm: {
				"1": "#F8F4B4",
				"2": "#F9E06C",
				"3": "#F4CE51",
				"4": "#F2BE37",
				"5": "#EDAC1E",
				"6": "#E59C19",
				"7": "#DF8F16",
				"8": "#D68019",
				"9": "#CF731C",
				"10": "#BD591B",
				"11": "#C3621B",
				"12": "#C86B1B",
				"13": "#C05727",
				"14": "#AD4417",
				"15": "#AE4818",
				"16": "#AD4417",
				"17": "#A73D15",
				"18": "#A23A15",
				"19": "#9D3414",
				"20": "#983015",
				"21": "#932A14",
				"22": "#8D2615",
				"23": "#892515",
				"24": "#832212",
				"25": "#7D200F",
				"26": "#771E0E",
				"27": "#731C0B",
				"28": "#70180C",
				"29": "#6A160C",
				"30": "#67120B",
				"31": "#63100A",
				"32": "#5F0E0A",
				"33": "#5B0B0A",
				"34": "#58080B",
				"35": "#4B090B",
				"36": "#4B090B",
				"37": "#470D0C",
				"38": "#400C0E",
				"39": "#3C0B0E",
				"40": "#240A0B",
			},
			flower: {
				cider: {
					straw: "#F8F4B4",
					yellow: "#F9E06C",
					gold: "#F4CE51",
					amber: "#C3621B",
					copper: "#AE4818",
					pink: "#FF66FF",
					ruby: "#B30F0F",
					purple: "#750075",
					garnet: "#6C0909",
				},
				fruity: {
					fruity: "#FF0000",
					berry: "#990000",
					apple: "#CC0100",
					stonefruit: "#E06666",
					tropical: "#EA9999",
					melon: "#EA9999",
					citrus: "#F4CCCC",
				},
				floral: "#FF9902",
				herbaceous: "#FFFF04",
				grassy: "#92C47C",
				vegetal: "#284E13",
				earthy: "#7F6001",
				woody: "#BF9001",
				spicy: "#B45F05",
				sweetaromatic: "#741C47",
			},
			white: "#FFFFFF",
			black: "#000000",
			transparent: "rgba(0,0,0,0)",
		},
		backgroundImage: {
			"gradient-primary": "linear-gradient(76.44deg, #5D810E 0%, #B2EA39 100%)",
			"gradient-secondary":
				"linear-gradient(76.44deg, #003B71 0%, #67B6FF 100%)",
		},
		borderRadius: {
			none: "0px",
			sm: "0.25rem",
			DEFAULT: "0.25rem",
			md: "0.375rem",
			lg: "0.5rem",
			xl: "0.75rem",
			"2xl": "1rem",
			"3xl": "1.5rem",
			full: "9999px",
		},
		fontSize: {
			xxs: [".625rem", "0.75rem"],
			xs: [".75rem", "1rem"],
			sm: [".875rem", "1.125rem"],
			base: ["1rem", "1.5rem"],
			lg: ["1.125rem", "1.25rem"],
			xl: ["1.25rem", "1.5rem"],
			"2xl": ["1.5rem", "1.75rem"],
			"3xl": ["1.875rem", "2rem"],
			"4xl": ["2.25rem", "2.5rem"],
			"5xl": ["3rem", "3.25rem"],
			"6xl": ["3.25rem", "3.5rem"],
		},
		headingSize: {
			h1: "h1",
			h2: "h2",
			h3: "h3",
			h4: "h4",
			h5: "h5",
			h6: "h6",
		},
		fontFamily: {
			sans: ["Lato", "sans-serif"],
			serif: ["Lora", "serif"],
		},
		fontWeights: {
			normal: "400",
			semibold: "500",
			bold: "600",
			extrabold: "900",
		},
		extend: {
			animation: {
				"pulse-fast": "pulse 1s ease-in-out infinite",
				waves: "waves 6s ease-in-out infinite",
			},
			colors: {
				primary: "#5D810E",
				secondary: "#005FB8",
				info: "#0074E0",
				success: "#547E11",
				warning: "#EED202",
				error: "#C93B3B",
				light: "#FCFDFD",
				medium: "#5E5E5E",
				dark: "#333333",
			},
			keyframes: {
				waves: {
					"0%, 100%": {
						transform: "rotate(-3deg)",
					},
					"50%": {
						transform: "translate(-100px,6px)",
					},
				},
			},
			borderWidth: {
				px: "1px",
				"1": ".125rem",
			},
			boxShadow: {
				transparent:
					"0px 0px 0px -1px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0)",
				default:
					"0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #E0E0E0",
				primary: "0px 0px 0px 3px #CBF17A",
				secondary:
					"0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #67B6FF",
				info: "0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #B8DDFF",
				success: "0px 0px 0px 3px #B2EA39",
				warning: "0px 0px 0px 3px #FDE744",
				error: "0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #F58585",
				link: "none",
				light: "0px 0px 0px 3px #E0E0E0",
				dark: "0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #B5B5B5",
			},
			translate: {
				"2xfull": "200%",
			},
			minWidth: {
				"1": "0.25rem",
				"2": "0.5rem",
				"4": "1rem",
				"6": "1.5rem",
				"8": "2rem",
				"10": "2.5rem",
				"12": "3rem",
				"16": "4rem",
				"20": "5rem",
				"24": "6rem",
				"32": "8rem",
				"36": "9rem",
				"48": "12rem",
				"56": "14rem",
				"64": "16rem",
				"72": "18rem",
				"80": "20rem",
				"88": "22rem",
				"96": "24rem",
				"100": "28rem",
				"104": "32rem",
				"106": "36rem",
				"1/2": "50%",
				"screen-20": "20vw",
				"screen-33": "33vw",
				"screen-40": "40vw",
				"screen-50": "50vw",
				"screen-66": "66vw",
				"screen-80": "80vw",
				full: "100%",
			},
			width: {
				"88": "22rem",
				"screen-20": "20vw",
				"screen-33": "33vw",
				"screen-40": "40vw",
				"screen-50": "50vw",
				"screen-66": "66vw",
				"screen-80": "80vw",
				full: "100%",
			},
			minHeight: {
				"1": "0.25rem",
				"2": "0.5rem",
				"4": "1rem",
				"6": "1.5rem",
				"8": "2rem",
				"10": "2.5rem",
				"12": "3rem",
				"16": "4rem",
				"20": "5rem",
				"24": "6rem",
				"32": "8rem",
				"36": "9rem",
				"48": "12rem",
				"56": "14rem",
				"64": "16rem",
				"72": "18rem",
				"80": "20rem",
				"88": "22rem",
				"96": "24rem",
				"100": "28rem",
				"104": "32rem",
				"106": "36rem",
				"screen-20": "20vh",
				"screen-33": "33vh",
				"screen-40": "40vh",
				"screen-50": "50vh",
				"screen-66": "66vh",
				"screen-80": "80vh",
			},
			maxHeight: {
				auto: "auto",
				"0": "0",
			},
		},
		icons: {
			Avatar: "Avatar",
			"HeroIconsSolid.CurrencyDollar": "HeroIconsSolid.CurrencyDollar",
			Arcadegames: "Arcadegames",
			ArrowPointer: "ArrowPointer",
			Awards: "Awards",
			Beachside: "Beachside",
			Beer: "Beer",
			BeerGarden: "BeerGarden",
			BeerRoutes: "BeerRoutes",
			Bike: "Bike",
			BoardGames: "BoardGames",
			Bookmark: "Bookmark",
			Bookmarked: "Bookmarked",
			Brew: "Brew",
			BrewInventory: "BrewInventory",
			BrewOnDeck: "BrewOnDeck",
			BrewOnTap: "BrewOnTap",
			CallAhead: "CallAhead",
			Car: "Car",
			Chat: "Chat",
			Cheers: "Cheers",
			Cider: "Cider",
			Circle: "Circle",
			Close: "Close",
			Cocktails: "Cocktails",
			Coffee: "Coffee",
			Dairy: "Dairy",
			DaysOnHand: "DaysOnHand",
			DaysOnTap: "DaysOnTap",
			DepletionRate: "DepletionRate",
			Dogs: "Dogs",
			Dollar: "Dollar",
			DoubleChevronLeft: "DoubleChevronLeft",
			DoubleChevronRight: "DoubleChevronRight",
			Draggable: "Draggable",
			Edit: "Edit",
			Empty: "Empty",
			Facebook: "Facebook",
			FamilyFriendly: "FamilyFriendly",
			FarmToTable: "FarmToTable",
			Food: "Food",
			FoodTruck: "FoodTruck",
			FreeRange: "FreeRange",
			Fresh: "Fresh",
			FullServiceMenu: "FullServiceMenu",
			Glass: "Glass",
			Globe: "Globe",
			GlutenFree: "GlutenFree",
			"HeroIcons.AcademicCap": "HeroIcons.AcademicCap",
			"HeroIcons.Adjustments": "HeroIcons.Adjustments",
			"HeroIcons.ChatAlt2": "HeroIcons.ChatAlt2",
			"HeroIcons.Annotation": "HeroIcons.Annotation",
			"HeroIcons.Archive": "HeroIcons.Archive",
			"HeroIcons.ArrowDown": "HeroIcons.ArrowDown",
			"HeroIcons.ArrowLeft": "HeroIcons.ArrowLeft",
			"HeroIcons.ArrowNarrowDown": "HeroIcons.ArrowNarrowDown",
			"HeroIcons.ArrowNarrowLeft": "HeroIcons.ArrowNarrowLeft",
			"HeroIcons.ArrowNarrowRight": "HeroIcons.ArrowNarrowRight",
			"HeroIcons.ArrowNarrowUp": "HeroIcons.ArrowNarrowUp",
			"HeroIcons.ArrowRight": "HeroIcons.ArrowRight",
			"HeroIcons.ArrowsExpand": "HeroIcons.ArrowsExpand",
			"HeroIcons.ArrowUp": "HeroIcons.ArrowUp",
			"HeroIcons.AtSymbol": "HeroIcons.AtSymbol",
			"HeroIcons.Backspace": "HeroIcons.Backspace",
			"HeroIcons.BadgeCheck": "HeroIcons.BadgeCheck",
			"HeroIcons.Ban": "HeroIcons.Ban",
			"HeroIcons.Bell": "HeroIcons.Bell",
			"HeroIcons.Cake": "HeroIcons.Cake",
			"HeroIcons.Calculator": "HeroIcons.Calculator",
			"HeroIcons.Calendar": "HeroIcons.Calendar",
			"HeroIcons.Cash": "HeroIcons.Cash",
			"HeroIcons.ChartBar": "HeroIcons.ChartBar",
			"HeroIcons.ChartPie": "HeroIcons.ChartPie",
			"HeroIcons.ChartSquareBar": "HeroIcons.ChartSquareBar",
			"HeroIcons.Chat": "HeroIcons.Chat",
			"HeroIcons.ChatAlt": "HeroIcons.ChatAlt",
			"HeroIcons.Check": "HeroIcons.Check",
			"HeroIcons.ChevronDown": "HeroIcons.ChevronDown",
			"HeroIcons.ChevronLeft": "HeroIcons.ChevronLeft",
			"HeroIcons.ChevronRight": "HeroIcons.ChevronRight",
			"HeroIcons.ChevronUp": "HeroIcons.ChevronUp",
			"HeroIcons.Chip": "HeroIcons.Chip",
			"HeroIcons.Clipboard": "HeroIcons.Clipboard",
			"HeroIcons.Clock": "HeroIcons.Clock",
			"HeroIcons.Cloud": "HeroIcons.Cloud",
			"HeroIcons.CloudDownload": "HeroIcons.CloudDownload",
			"HeroIcons.CloudUpload": "HeroIcons.CloudUpload",
			"HeroIcons.Code": "HeroIcons.Code",
			"HeroIcons.Cog": "HeroIcons.Cog",
			"HeroIcons.Collection": "HeroIcons.Collection",
			"HeroIcons.CreditCard": "HeroIcons.CreditCard",
			"HeroIcons.CurrencyDollar": "HeroIcons.CurrencyDollar",
			"HeroIcons.Database": "HeroIcons.Database",
			"HeroIcons.Document": "HeroIcons.Document",
			"HeroIcons.DocumentAdd": "HeroIcons.DocumentAdd",
			"HeroIcons.DocumentDownload": "HeroIcons.DocumentDownload",
			"HeroIcons.DocumentDuplicate": "HeroIcons.DocumentDuplicate",
			"HeroIcons.DocumentRemove": "HeroIcons.DocumentRemove",
			"HeroIcons.DocumentReport": "HeroIcons.DocumentReport",
			"HeroIcons.DocumentText": "HeroIcons.DocumentText",
			"HeroIcons.DotsHorizontal": "HeroIcons.DotsHorizontal",
			"HeroIcons.DotsVertical": "HeroIcons.DotsVertical",
			"HeroIcons.Download": "HeroIcons.Download",
			"HeroIcons.Duplicate": "HeroIcons.Duplicate",
			"HeroIcons.EmojiHappy": "HeroIcons.EmojiHappy",
			"HeroIcons.EmojiSad": "HeroIcons.EmojiSad",
			"HeroIcons.Exclamation": "HeroIcons.Exclamation",
			"HeroIcons.ExclamationCircle": "HeroIcons.ExclamationCircle",
			"HeroIcons.ExternalLink": "HeroIcons.ExternalLink",
			"HeroIcons.Eye": "HeroIcons.Eye",
			"HeroIcons.EyeOff": "HeroIcons.EyeOff",
			"HeroIcons.Filter": "HeroIcons.Filter",
			"HeroIcons.FingerPrint": "HeroIcons.FingerPrint",
			"HeroIcons.Fire": "HeroIcons.Fire",
			"HeroIcons.Flag": "HeroIcons.Flag",
			"HeroIcons.Folder": "HeroIcons.Folder",
			"HeroIcons.FolderAdd": "HeroIcons.FolderAdd",
			"HeroIcons.FolderDownload": "HeroIcons.FolderDownload",
			"HeroIcons.FolderOpen": "HeroIcons.FolderOpen",
			"HeroIcons.FolderRemove": "HeroIcons.FolderRemove",
			"HeroIcons.Gift": "HeroIcons.Gift",
			"HeroIcons.Globe": "HeroIcons.Globe",
			"HeroIcons.GlobeAlt": "HeroIcons.GlobeAlt",
			"HeroIcons.Hashtag": "HeroIcons.Hashtag",
			"HeroIcons.Heart": "HeroIcons.Heart",
			"HeroIcons.Identification": "HeroIcons.Identification",
			"HeroIcons.InformationCircle": "HeroIcons.InformationCircle",
			"HeroIcons.Key": "HeroIcons.Key",
			"HeroIcons.Library": "HeroIcons.Library",
			"HeroIcons.LightBulb": "HeroIcons.LightBulb",
			"HeroIcons.LocationMarker": "HeroIcons.LocationMarker",
			"HeroIcons.LockClosed": "HeroIcons.LockClosed",
			"HeroIcons.LockOpen": "HeroIcons.LockOpen",
			"HeroIcons.Login": "HeroIcons.Login",
			"HeroIcons.Logout": "HeroIcons.Logout",
			"HeroIcons.Mail": "HeroIcons.Mail",
			"HeroIcons.Map": "HeroIcons.Map",
			"HeroIcons.Menu": "HeroIcons.Menu",
			"HeroIcons.Minus": "HeroIcons.Minus",
			"HeroIcons.Newspaper": "HeroIcons.Newspaper",
			"HeroIcons.OfficeBuilding": "HeroIcons.OfficeBuilding",
			"HeroIcons.PaperAirplane": "HeroIcons.PaperAirplane",
			"HeroIcons.PaperClip": "HeroIcons.PaperClip",
			"HeroIcons.Pause": "HeroIcons.Pause",
			"HeroIcons.Pencil": "HeroIcons.Pencil",
			"HeroIcons.Phone": "HeroIcons.Phone",
			"HeroIcons.Photograph": "HeroIcons.Photograph",
			"HeroIcons.Play": "HeroIcons.Play",
			"HeroIcons.Plus": "HeroIcons.Plus",
			"HeroIcons.PlusCircle": "HeroIcons.PlusCircle",
			"HeroIcons.Printer": "HeroIcons.Printer",
			"HeroIcons.Puzzle": "HeroIcons.Puzzle",
			"HeroIcons.Qrcode": "HeroIcons.Qrcode",
			"HeroIcons.QuestionMarkCircle": "HeroIcons.QuestionMarkCircle",
			"HeroIcons.Refresh": "HeroIcons.Refresh",
			"HeroIcons.Reply": "HeroIcons.Reply",
			"HeroIcons.Rss": "HeroIcons.Rss",
			"HeroIcons.SaveAs": "HeroIcons.SaveAs",
			"HeroIcons.Search": "HeroIcons.Search",
			"HeroIcons.Selector": "HeroIcons.Selector",
			"HeroIcons.Server": "HeroIcons.Server",
			"HeroIcons.Share": "HeroIcons.Share",
			"HeroIcons.SortAscending": "HeroIcons.SortAscending",
			"HeroIcons.SortDescending": "HeroIcons.SortDescending",
			"HeroIcons.Sparkles": "HeroIcons.Sparkles",
			"HeroIcons.Star": "HeroIcons.Star",
			"HeroIcons.StatusOffline": "HeroIcons.StatusOffline",
			"HeroIcons.StatusOnline": "HeroIcons.StatusOnline",
			"HeroIcons.Stop": "HeroIcons.Stop",
			"HeroIcons.Support": "HeroIcons.Support",
			"HeroIcons.SwitchHorizontal": "HeroIcons.SwitchHorizontal",
			"HeroIcons.SwitchVertical": "HeroIcons.SwitchVertical",
			"HeroIcons.Table": "HeroIcons.Table",
			"HeroIcons.Tag": "HeroIcons.Tag",
			"HeroIcons.Template": "HeroIcons.Template",
			"HeroIcons.ThumbDown": "HeroIcons.ThumbDown",
			"HeroIcons.ThumbUp": "HeroIcons.ThumbUp",
			"HeroIcons.Ticket": "HeroIcons.Ticket",
			"HeroIcons.Trash": "HeroIcons.Trash",
			"HeroIcons.TrendingDown": "HeroIcons.TrendingDown",
			"HeroIcons.TrendingUp": "HeroIcons.TrendingUp",
			"HeroIcons.Truck": "HeroIcons.Truck",
			"HeroIcons.Upload": "HeroIcons.Upload",
			"HeroIcons.User": "HeroIcons.User",
			"HeroIcons.UserAdd": "HeroIcons.UserAdd",
			"HeroIcons.UserCircle": "HeroIcons.UserCircle",
			"HeroIcons.UserGroup": "HeroIcons.UserGroup",
			"HeroIcons.UserRemove": "HeroIcons.UserRemove",
			"HeroIcons.Users": "HeroIcons.Users",
			"HeroIcons.Variable": "HeroIcons.Variable",
			"HeroIcons.VideoCamera": "HeroIcons.VideoCamera",
			"HeroIcons.ViewBoards": "HeroIcons.ViewBoards",
			"HeroIcons.ViewGrid": "HeroIcons.ViewGrid",
			"HeroIcons.ViewList": "HeroIcons.ViewList",
			"HeroIcons.Wifi": "HeroIcons.Wifi",
			"HeroIcons.X": "HeroIcons.X",
			"HeroIcons.XCircle": "HeroIcons.XCircle",
			"HeroIcons.ZoomIn": "HeroIcons.ZoomIn",
			"HeroIcons.ZoomOut": "HeroIcons.ZoomOut",
			"HeroIconsSolid.AcademicCap": "HeroIconsSolid.AcademicCap",
			"HeroIconsSolid.Adjustments": "HeroIconsSolid.Adjustments",
			"HeroIconsSolid.ChatAlt2": "HeroIconsSolid.ChatAlt2",
			"HeroIconsSolid.Annotation": "HeroIconsSolid.Annotation",
			"HeroIconsSolid.Archive": "HeroIconsSolid.Archive",
			"HeroIconsSolid.ArrowDown": "HeroIconsSolid.ArrowDown",
			"HeroIconsSolid.ArrowLeft": "HeroIconsSolid.ArrowLeft",
			"HeroIconsSolid.ArrowNarrowDown": "HeroIconsSolid.ArrowNarrowDown",
			"HeroIconsSolid.ArrowNarrowLeft": "HeroIconsSolid.ArrowNarrowLeft",
			"HeroIconsSolid.ArrowNarrowRight": "HeroIconsSolid.ArrowNarrowRight",
			"HeroIconsSolid.ArrowNarrowUp": "HeroIconsSolid.ArrowNarrowUp",
			"HeroIconsSolid.ArrowRight": "HeroIconsSolid.ArrowRight",
			"HeroIconsSolid.ArrowsExpand": "HeroIconsSolid.ArrowsExpand",
			"HeroIconsSolid.ArrowUp": "HeroIconsSolid.ArrowUp",
			"HeroIconsSolid.AtSymbol": "HeroIconsSolid.AtSymbol",
			"HeroIconsSolid.Backspace": "HeroIconsSolid.Backspace",
			"HeroIconsSolid.Ban": "HeroIconsSolid.Ban",
			"HeroIconsSolid.Bell": "HeroIconsSolid.Bell",
			"HeroIconsSolid.Cake": "HeroIconsSolid.Cake",
			"HeroIconsSolid.Calculator": "HeroIconsSolid.Calculator",
			"HeroIconsSolid.Calendar": "HeroIconsSolid.Calendar",
			"HeroIconsSolid.Cash": "HeroIconsSolid.Cash",
			"HeroIconsSolid.ChartBar": "HeroIconsSolid.ChartBar",
			"HeroIconsSolid.ChartPie": "HeroIconsSolid.ChartPie",
			"HeroIconsSolid.ChartSquareBar": "HeroIconsSolid.ChartSquareBar",
			"HeroIconsSolid.Chat": "HeroIconsSolid.Chat",
			"HeroIconsSolid.ChatAlt": "HeroIconsSolid.ChatAlt",
			"HeroIconsSolid.Check": "HeroIconsSolid.Check",
			"HeroIconsSolid.ChevronDown": "HeroIconsSolid.ChevronDown",
			"HeroIconsSolid.ChevronLeft": "HeroIconsSolid.ChevronLeft",
			"HeroIconsSolid.ChevronRight": "HeroIconsSolid.ChevronRight",
			"HeroIconsSolid.ChevronUp": "HeroIconsSolid.ChevronUp",
			"HeroIconsSolid.Chip": "HeroIconsSolid.Chip",
			"HeroIconsSolid.Clipboard": "HeroIconsSolid.Clipboard",
			"HeroIconsSolid.Clock": "HeroIconsSolid.Clock",
			"HeroIconsSolid.Cloud": "HeroIconsSolid.Cloud",
			"HeroIconsSolid.CloudDownload": "HeroIconsSolid.CloudDownload",
			"HeroIconsSolid.CloudUpload": "HeroIconsSolid.CloudUpload",
			"HeroIconsSolid.Code": "HeroIconsSolid.Code",
			"HeroIconsSolid.Cog": "HeroIconsSolid.Cog",
			"HeroIconsSolid.Collection": "HeroIconsSolid.Collection",
			"HeroIconsSolid.CreditCard": "HeroIconsSolid.CreditCard",
			"HeroIconsSolid.Database": "HeroIconsSolid.Database",
			"HeroIconsSolid.Document": "HeroIconsSolid.Document",
			"HeroIconsSolid.DocumentAdd": "HeroIconsSolid.DocumentAdd",
			"HeroIconsSolid.DocumentDownload": "HeroIconsSolid.DocumentDownload",
			"HeroIconsSolid.DocumentDuplicate": "HeroIconsSolid.DocumentDuplicate",
			"HeroIconsSolid.DocumentRemove": "HeroIconsSolid.DocumentRemove",
			"HeroIconsSolid.DocumentReport": "HeroIconsSolid.DocumentReport",
			"HeroIconsSolid.DocumentText": "HeroIconsSolid.DocumentText",
			"HeroIconsSolid.DotsHorizontal": "HeroIconsSolid.DotsHorizontal",
			"HeroIconsSolid.DotsVertical": "HeroIconsSolid.DotsVertical",
			"HeroIconsSolid.Download": "HeroIconsSolid.Download",
			"HeroIconsSolid.Duplicate": "HeroIconsSolid.Duplicate",
			"HeroIconsSolid.EmojiHappy": "HeroIconsSolid.EmojiHappy",
			"HeroIconsSolid.EmojiSad": "HeroIconsSolid.EmojiSad",
			"HeroIconsSolid.Exclamation": "HeroIconsSolid.Exclamation",
			"HeroIconsSolid.ExclamationCircle": "HeroIconsSolid.ExclamationCircle",
			"HeroIconsSolid.ExternalLink": "HeroIcons.ExternalLink",
			"HeroIconsSolid.Eye": "HeroIconsSolid.Eye",
			"HeroIconsSolid.EyeOff": "HeroIconsSolid.EyeOff",
			"HeroIconsSolid.Filter": "HeroIconsSolid.Filter",
			"HeroIconsSolid.FingerPrint": "HeroIconsSolid.FingerPrint",
			"HeroIconsSolid.Fire": "HeroIconsSolid.Fire",
			"HeroIconsSolid.Flag": "HeroIconsSolid.Flag",
			"HeroIconsSolid.Folder": "HeroIconsSolid.Folder",
			"HeroIconsSolid.FolderAdd": "HeroIconsSolid.FolderAdd",
			"HeroIconsSolid.FolderDownload": "HeroIconsSolid.FolderDownload",
			"HeroIconsSolid.FolderOpen": "HeroIconsSolid.FolderOpen",
			"HeroIconsSolid.FolderRemove": "HeroIconsSolid.FolderRemove",
			"HeroIconsSolid.Gift": "HeroIconsSolid.Gift",
			"HeroIconsSolid.Globe": "HeroIconsSolid.Globe",
			"HeroIconsSolid.GlobeAlt": "HeroIconsSolid.GlobeAlt",
			"HeroIconsSolid.Hashtag": "HeroIconsSolid.Hashtag",
			"HeroIconsSolid.Heart": "HeroIconsSolid.Heart",
			"HeroIconsSolid.Identification": "HeroIconsSolid.Identification",
			"HeroIconsSolid.InformationCircle": "HeroIconsSolid.InformationCircle",
			"HeroIconsSolid.Key": "HeroIconsSolid.Key",
			"HeroIconsSolid.Library": "HeroIconsSolid.Library",
			"HeroIconsSolid.LightBulb": "HeroIconsSolid.LightBulb",
			"HeroIconsSolid.LocationMarker": "HeroIconsSolid.LocationMarker",
			"HeroIconsSolid.LockClosed": "HeroIconsSolid.LockClosed",
			"HeroIconsSolid.LockOpen": "HeroIconsSolid.LockOpen",
			"HeroIconsSolid.Login": "HeroIconsSolid.Login",
			"HeroIconsSolid.Logout": "HeroIconsSolid.Logout",
			"HeroIconsSolid.Mail": "HeroIconsSolid.Mail",
			"HeroIconsSolid.Map": "HeroIconsSolid.Map",
			"HeroIconsSolid.Menu": "HeroIconsSolid.Menu",
			"HeroIconsSolid.Minus": "HeroIconsSolid.Minus",
			"HeroIconsSolid.Newspaper": "HeroIconsSolid.Newspaper",
			"HeroIconsSolid.OfficeBuilding": "HeroIconsSolid.OfficeBuilding",
			"HeroIconsSolid.PaperAirplane": "HeroIconsSolid.PaperAirplane",
			"HeroIconsSolid.PaperClip": "HeroIconsSolid.PaperClip",
			"HeroIconsSolid.Pause": "HeroIconsSolid.Pause",
			"HeroIconsSolid.Pencil": "HeroIconsSolid.Pencil",
			"HeroIconsSolid.Phone": "HeroIconsSolid.Phone",
			"HeroIconsSolid.Photograph": "HeroIconsSolid.Photograph",
			"HeroIconsSolid.Play": "HeroIconsSolid.Play",
			"HeroIconsSolid.Plus": "HeroIconsSolid.Plus",
			"HeroIconsSolid.PlusCircle": "HeroIconsSolid.PlusCircle",
			"HeroIconsSolid.Printer": "HeroIconsSolid.Printer",
			"HeroIconsSolid.Puzzle": "HeroIconsSolid.Puzzle",
			"HeroIconsSolid.Qrcode": "HeroIconsSolid.Qrcode",
			"HeroIconsSolid.QuestionMarkCircle": "HeroIconsSolid.QuestionMarkCircle",
			"HeroIconsSolid.Refresh": "HeroIconsSolid.Refresh",
			"HeroIconsSolid.Reply": "HeroIconsSolid.Reply",
			"HeroIconsSolid.Rss": "HeroIconsSolid.Rss",
			"HeroIconsSolid.SaveAs": "HeroIconsSolid.SaveAs",
			"HeroIconsSolid.Search": "HeroIconsSolid.Search",
			"HeroIconsSolid.Selector": "HeroIconsSolid.Selector",
			"HeroIconsSolid.Server": "HeroIconsSolid.Server",
			"HeroIconsSolid.Share": "HeroIconsSolid.Share",
			"HeroIconsSolid.SortAscending": "HeroIconsSolid.SortAscending",
			"HeroIconsSolid.SortDescending": "HeroIconsSolid.SortDescending",
			"HeroIconsSolid.Sparkles": "HeroIconsSolid.Sparkles",
			"HeroIconsSolid.Star": "HeroIconsSolid.Star",
			"HeroIconsSolid.StatusOffline": "HeroIconsSolid.StatusOffline",
			"HeroIconsSolid.StatusOnline": "HeroIconsSolid.StatusOnline",
			"HeroIconsSolid.Stop": "HeroIconsSolid.Stop",
			"HeroIconsSolid.Support": "HeroIconsSolid.Support",
			"HeroIconsSolid.SwitchHorizontal": "HeroIconsSolid.SwitchHorizontal",
			"HeroIconsSolid.SwitchVertical": "HeroIconsSolid.SwitchVertical",
			"HeroIconsSolid.Table": "HeroIconsSolid.Table",
			"HeroIconsSolid.Tag": "HeroIconsSolid.Tag",
			"HeroIconsSolid.Template": "HeroIconsSolid.Template",
			"HeroIconsSolid.ThumbDown": "HeroIconsSolid.ThumbDown",
			"HeroIconsSolid.ThumbUp": "HeroIconsSolid.ThumbUp",
			"HeroIconsSolid.Ticket": "HeroIconsSolid.Ticket",
			"HeroIconsSolid.Trash": "HeroIconsSolid.Trash",
			"HeroIconsSolid.TrendingDown": "HeroIconsSolid.TrendingDown",
			"HeroIconsSolid.TrendingUp": "HeroIconsSolid.TrendingUp",
			"HeroIconsSolid.Truck": "HeroIconsSolid.Truck",
			"HeroIconsSolid.Upload": "HeroIconsSolid.Upload",
			"HeroIconsSolid.UserAdd": "HeroIconsSolid.UserAdd",
			"HeroIconsSolid.UserCircle": "HeroIconsSolid.UserCircle",
			"HeroIconsSolid.UserGroup": "HeroIconsSolid.UserGroup",
			"HeroIconsSolid.UserRemove": "HeroIconsSolid.UserRemove",
			"HeroIconsSolid.Users": "HeroIconsSolid.Users",
			"HeroIconsSolid.Variable": "HeroIconsSolid.Variable",
			"HeroIconsSolid.VideoCamera": "HeroIconsSolid.VideoCamera",
			"HeroIconsSolid.ViewBoards": "HeroIconsSolid.ViewBoards",
			"HeroIconsSolid.ViewGrid": "HeroIconsSolid.ViewGrid",
			"HeroIconsSolid.ViewList": "HeroIconsSolid.ViewList",
			"HeroIconsSolid.Wifi": "HeroIconsSolid.Wifi",
			"HeroIconsSolid.X": "HeroIconsSolid.X",
			"HeroIconsSolid.XCircle": "HeroIconsSolid.XCircle",
			"HeroIconsSolid.ZoomIn": "HeroIconsSolid.ZoomIn",
			"HeroIconsSolid.ZoomOut": "HeroIconsSolid.ZoomOut",
			Indoor: "Indoor",
			IndoorSeating: "IndoorSeating",
			Instagram: "Instagram",
			Karaoke: "Karaoke",
			Keg: "Keg",
			KickDate: "KickDate",
			Kombucha: "Kombucha",
			LateNightMenu: "LateNightMenu",
			Learn: "Learn",
			Levels: "Levels",
			LevelsKeg: "LevelsKeg",
			LimitedMenu: "LimitedMenu",
			ListView: "ListView",
			LiveMusic: "LiveMusic",
			Loading: "Loading",
			Map: "Map",
			Mead: "Mead",
			MergeKeg: "MergeKeg",
			MountainSide: "MountainSide",
			MusicNote: "MusicNote",
			NewKeg: "NewKeg",
			NonAlcoholic: "NonAlcoholic",
			NonDairy: "NonDairy",
			Notes: "Notes",
			Orderin: "Orderin",
			Outdoor: "Outdoor",
			Outdoors: "Outdoors",
			Oz: "Oz",
			Patio: "Patio",
			Percentage: "Percentage",
			PetFriendly: "PetFriendly",
			Pets: "Pets",
			Pint: "Pint",
			ProductsArchive: "ProductsArchive",
			Refrigerator: "Refrigerator",
			Reservations: "Reservations",
			Rooftop: "Rooftop",
			Routes: "Routes",
			Search: "Search",
			Seat: "Seat",
			Seltzer: "Seltzer",
			Settings: "Settings",
			SignPost: "SignPost",
			Speedometer: "Speedometer",
			Tank: "Tank",
			Tap: "Tap",
			TapNumber: "TapNumber",
			Twitter: "Twitter",
			Vegan: "Vegan",
			Vegetarian: "Vegetarian",
			Vessel1_2Bbl: "Vessel1_2Bbl",
			Vessel1_4Bbl: "Vessel1_4Bbl",
			Vessel1_6Bbl: "Vessel1_6Bbl",
			Vessel10L: "Vessel10L",
			Vessel20L: "Vessel20L",
			Vessel25L: "Vessel25L",
			Vessel30L: "Vessel30L",
			Vessel40L: "Vessel40L",
			Vessel50L: "Vessel50L",
			VesselCornelius: "VesselCornelius",
			VesselPet1_6Bbl: "VesselPet1_6Bbl",
			VesselPet10L: "VesselPet10L",
			VesselPet125L: "VesselPet125L",
			VesselPet12L: "VesselPet12L",
			VesselPet20L: "VesselPet20L",
			VesselPet24L: "VesselPet24L",
			VesselPet30L: "VesselPet30L",
			Vimeo: "Vimeo",
			Walk: "Walk",
			Waterfront: "Waterfront",
			Wine: "Wine",
			Yoga: "Yoga",
			Youtube: "Youtube",
		},
		atomicSizes: {
			xs: "xs",
			sm: "sm",
			md: "md",
			lg: "lg",
			xl: "xl",
			"2xl": "2xl",
			"3xl": "3xl",
			"4xl": "4xl",
			"5xl": "5xl",
			"6xl": "6xl",
		},
		atomicThemes: {
			primary: "primary",
			secondary: "secondary",
			info: "info",
			success: "success",
			warning: "warning",
			error: "error",
			light: "light",
			dark: "dark",
			link: "link",
			default: "default",
		},
		componentThemes: {
			light: "light",
			dark: "dark",
		},
		componentSizes: {
			xsmall: "xs",
			small: "sm",
			medium: "md",
			large: "lg",
		},
		translate: {
			"2xfull": "200%",
		},
		minWidth: {
			"1": "0.25rem",
			"2": "0.5rem",
			"4": "1rem",
			"6": "1.5rem",
			"8": "2rem",
			"10": "2.5rem",
			"12": "3rem",
			"16": "4rem",
			"20": "5rem",
			"24": "6rem",
			"32": "8rem",
			"36": "9rem",
			"48": "12rem",
			"64": "16rem",
			"72": "18rem",
			"80": "20rem",
			"88": "22rem",
			"96": "24rem",
			"100": "28rem",
			"104": "32rem",
			"106": "36rem",
			"1/2": "50%",
			"screen-20": "20vw",
			"screen-33": "33vw",
			"screen-40": "40vw",
			"screen-50": "50vw",
			"screen-66": "66vw",
			"screen-80": "80vw",
			full: "100%",
		},
		width: {
			"88": "22rem",
			"screen-20": "20vw",
			"screen-33": "33vw",
			"screen-40": "40vw",
			"screen-50": "50vw",
			"screen-66": "66vw",
			"screen-80": "80vw",
			full: "100%",
		},
		minHeight: {
			"screen-20": "20vh",
			"screen-33": "33vh",
			"screen-40": "40vh",
			"screen-50": "50vh",
			"screen-66": "66vh",
			"screen-80": "80vh",
		},
		maxHeight: {
			auto: "auto",
			"0": "0",
		},
	},
	glasswareIcons: {
		GlasswareArome: "GlasswareArome",
		GlasswareBoot: "GlasswareBoot",
		GlasswareBottle: "GlasswareBottle",
		GlasswareCan: "GlasswareCan",
		GlasswareCanGlass: "GlasswareCanGlass",
		GlasswareChalice: "GlasswareChalice",
		GlasswareCoffeeCup: "GlasswareCoffeeCup",
		GlasswareCraftmaster: "GlasswareCraftmaster",
		GlasswareCrowler: "GlasswareCrowler",
		GlasswareFlaredPint: "GlasswareFlaredPint",
		GlasswareFlute: "GlasswareFlute",
		GlasswareGlencairnWhiskey: "GlasswareGlencairnWhiskey",
		GlasswareGoblet: "GlasswareGoblet",
		GlasswareHighball: "GlasswareHighball",
		GlasswareLargeGrowler: "GlasswareLargeGrowler",
		GlasswareLowball: "GlasswareLowball",
		GlasswareMargarita: "GlasswareMargarita",
		GlasswareMartini: "GlasswareMartini",
		GlasswareMug: "GlasswareMug",
		GlasswareNonicPint: "GlasswareNonicPint",
		GlasswarePilsner: "GlasswarePilsner",
		GlasswarePitcher: "GlasswarePitcher",
		GlasswareSamAdamsPint: "GlasswareSamAdamsPint",
		GlasswareSampler: "GlasswareSampler",
		GlasswareShakerPint: "GlasswareShakerPint",
		GlasswareSmallGrowler: "GlasswareSmallGrowler",
		GlasswareSnifter: "GlasswareSnifter",
		GlasswareSpiegelauIPA: "GlasswareSpiegelauIPA",
		GlasswareSpiegelauStout: "GlasswareSpiegelauStout",
		GlasswareSpiegelauWheat: "GlasswareSpiegelauWheat",
		GlasswareStange: "GlasswareStange",
		GlasswareStemlessWine: "GlasswareStemlessWine",
		GlasswareTeku: "GlasswareTeku",
		GlasswareThistle: "GlasswareThistle",
		GlasswareTulip: "GlasswareTulip",
		GlasswareTulipPint: "GlasswareTulipPint",
		GlasswareTumbler: "GlasswareTumbler",
		GlasswareWeizen: "GlasswareWeizen",
		GlasswareWilli: "GlasswareWilli",
		GlasswareWine: "GlasswareWine",
		GlasswareYardtube: "GlasswareYardtube",
	},
	atomicSizes: {
		xs: "xs",
		sm: "sm",
		md: "md",
		lg: "lg",
		xl: "xl",
		"2xl": "2xl",
		"3xl": "3xl",
		"4xl": "4xl",
		"5xl": "5xl",
		"6xl": "6xl",
	},
	atomicThemes: {
		primary: "primary",
		secondary: "secondary",
		info: "info",
		success: "success",
		warning: "warning",
		error: "error",
		light: "light",
		dark: "dark",
		link: "link",
		default: "default",
	},
	componentThemes: {
		light: "light",
		dark: "dark",
	},
	componentSizes: {
		xsmall: "xs",
		small: "sm",
		medium: "md",
		large: "lg",
	},
	positions: {
		left: "left",
		right: "right",
		top: "top",
		bottom: "bottom",
	},
	variants: {
		extend: {},
	},
};
